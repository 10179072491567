<template>
  <tbody>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
    <tr>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
          <div class="Polaris-SkeletonBodyText"></div>
          <div class="Polaris-SkeletonBodyText"></div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
        <div>
          <div class="Polaris-SkeletonBodyText__SkeletonBodyTextContainer">
            <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
            </div>
          </div>
        </div>
      </td>
      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric ">
        <div class="Polaris-SkeletonDisplayText__DisplayText Polaris-SkeletonDisplayText--sizeSmall ml-auto w-50">
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
  export default {

  }
</script>

<style>

</style>