<template>
  <div class="Polaris-Page">
    <div class="Polaris-Page-Header">
      <div class="Polaris-Page-Header__MainContent">
        <div class="Polaris-Page-Header__TitleActionMenuWrapper">
          <div>
            <div class="Polaris-Header-Title__TitleAndSubtitleWrapper">
              <div class="Polaris-Header-Title">
                <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeLarge">Orders</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Polaris-Page__Content">
      <div class="Polaris-Card">
        <div>
          <div v-if="!loading.ordersList">
            <div class="Polaris-DataTable" v-if=" ordersList.length > 0 ">
              <div class="Polaris-DataTable__ScrollContainer">
                <table class="Polaris-DataTable__Table">
                  <thead>
                    <tr>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                        scope="col"> Order </th>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                        scope="col"> Shipping to</th>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                        scope="col"> Total </th>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                        scope="col"> Status </th>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                        scope="col"> Payment status </th>
                      <th data-polaris-header-cell="true"
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                        scope="col"> Shipment status </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="Polaris-DataTable__TableRow" v-for=" (order, index) in ordersList " :key="index">
                      <th
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric text-left">
                        <router-link :to="'/shopify/orders/' + order.id " v-text=" '#' + order.number "> </router-link>
                      </th>
                      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop 
                    Polaris-DataTable__Cell--numeric">
                        <i class="flag-icon mr-2"
                          :class=" 'flag-icon-' + (order.shipping_address.country.code).toLowerCase() "></i>
                        <span v-text=" order.shipping_address.country.code "></span>
                      </td>
                      <td class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop 
                    Polaris-DataTable__Cell--numeric" v-text="fnFormatPrice( order.totals.total , order.currency )">
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
                        <div class="Polaris-Stack__Item">
                          <span class="Polaris-Badge" :class=" { 'Polaris-Badge--statusSuccess':(order.order_status == 'closed') , 'Polaris-Badge--statusWarning':(order.order_status == 'cancelled'),
                            'Polaris-Badge--statusInfo':(order.order_status == 'open') }">
                            <span class="Polaris-Badge__Content text-capitalize "
                              v-text=" order.order_status == 'closed' ? 'Delivered': order.order_status "></span>
                          </span>
                        </div>
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
                        <div class="Polaris-Stack__Item">
                          <span class="Polaris-Badge" :class=" { 'Polaris-Badge--statusSuccess':(order.status == 'paid') , 'Polaris-Badge--statusWarning':(order.status == 'cancelled'),
                            'Polaris-Badge--statusAttention':(order.status == 'pending')  }">
                            <span class="Polaris-Badge__Content text-capitalize" v-text=" order.status  "></span>
                          </span>
                        </div>
                      </td>
                      <td
                        class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric">
                        <div class="Polaris-Stack__Item"> 
                          <span class="Polaris-Badge" :class=" { 'Polaris-Badge--statusSuccess':(order.shipment_status == 'ready to ship') , 'Polaris-Badge--statusWarning':(order.shipment_status == 'cancelled'),
                            'Polaris-Badge--statusAttention':(order.shipment_status == 'pending'), 'Polaris-Badge--statusSuccess':(order.shipment_status == 'delivered')   }">
                            <span class="Polaris-Badge__Content text-capitalize " v-text=" order.shipment_status "></span>
                          </span>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <PolarisMessage v-else title="No orders yet" message="You have no orders yet." />
          </div>
          <div v-else>
            <table class="Polaris-DataTable__Table">
              <thead>
                <tr>
                  <th data-polaris-header-cell="true"
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                    scope="col"> Order </th>
                  <th data-polaris-header-cell="true"
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                    scope="col"> Shipping to</th>
                  <th data-polaris-header-cell="true"
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                    scope="col"> Total </th>
                  <th data-polaris-header-cell="true"
                    class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                    scope="col"> Status </th>
                </tr>
              </thead>
              <TableSkeleton v-show=" ordersList.length == 0 " />
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import '@shopify/polaris/styles.css';
  import TableSkeleton from './components/prtoducts-table-skeleton';
  import PolarisMessage from '@/components/shopify/polaris-message';

  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex'

  export default {
    components: {
      TableSkeleton,
      PolarisMessage,
    },
    computed: {
      ...mapState('ShopifyOrders', ['ordersList', 'loading']),
    },
    methods: {
      ...mapActions('ShopifyOrders', ['fnApiGetOrders']),
     ...mapActions('ShopifyStore', ['fnApiGetStore', 'fnApiStoreStatus']),
      //   ...mapMutations('products', ['setModalSellOutOfStock']),
    },
    mounted() {
      this.fnApiGetOrders();
    }
  }
</script>

<style>

</style>