var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Polaris-Page"},[_vm._m(0),_c('div',{staticClass:"Polaris-Page__Content"},[_c('div',{staticClass:"Polaris-Card"},[_c('div',[(!_vm.loading.ordersList)?_c('div',[( _vm.ordersList.length > 0 )?_c('div',{staticClass:"Polaris-DataTable"},[_c('div',{staticClass:"Polaris-DataTable__ScrollContainer"},[_c('table',{staticClass:"Polaris-DataTable__Table"},[_vm._m(1),_c('tbody',_vm._l((_vm.ordersList),function(order,index){return _c('tr',{key:index,staticClass:"Polaris-DataTable__TableRow"},[_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric text-left"},[_c('router-link',{attrs:{"to":'/shopify/orders/' + order.id},domProps:{"textContent":_vm._s( '#' + order.number )}})],1),_c('td',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"},[_c('i',{staticClass:"flag-icon mr-2",class:'flag-icon-' + (order.shipping_address.country.code).toLowerCase()}),_c('span',{domProps:{"textContent":_vm._s( order.shipping_address.country.code )}})]),_c('td',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric",domProps:{"textContent":_vm._s(_vm.fnFormatPrice( order.totals.total , order.currency ))}}),_c('td',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"},[_c('div',{staticClass:"Polaris-Stack__Item"},[_c('span',{staticClass:"Polaris-Badge",class:{ 'Polaris-Badge--statusSuccess':(order.order_status == 'closed') , 'Polaris-Badge--statusWarning':(order.order_status == 'cancelled'),
                          'Polaris-Badge--statusInfo':(order.order_status == 'open') }},[_c('span',{staticClass:"Polaris-Badge__Content text-capitalize",domProps:{"textContent":_vm._s( order.order_status == 'closed' ? 'Delivered': order.order_status )}})])])]),_c('td',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"},[_c('div',{staticClass:"Polaris-Stack__Item"},[_c('span',{staticClass:"Polaris-Badge",class:{ 'Polaris-Badge--statusSuccess':(order.status == 'paid') , 'Polaris-Badge--statusWarning':(order.status == 'cancelled'),
                          'Polaris-Badge--statusAttention':(order.status == 'pending')  }},[_c('span',{staticClass:"Polaris-Badge__Content text-capitalize",domProps:{"textContent":_vm._s( order.status  )}})])])]),_c('td',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--numeric"},[_c('div',{staticClass:"Polaris-Stack__Item"},[_c('span',{staticClass:"Polaris-Badge",class:{ 'Polaris-Badge--statusSuccess':(order.shipment_status == 'ready to ship') , 'Polaris-Badge--statusWarning':(order.shipment_status == 'cancelled'),
                          'Polaris-Badge--statusAttention':(order.shipment_status == 'pending'), 'Polaris-Badge--statusSuccess':(order.shipment_status == 'delivered')   }},[_c('span',{staticClass:"Polaris-Badge__Content text-capitalize",domProps:{"textContent":_vm._s( order.shipment_status )}})])])])])}),0)])])]):_c('PolarisMessage',{attrs:{"title":"No orders yet","message":"You have no orders yet."}})],1):_c('div',[_c('table',{staticClass:"Polaris-DataTable__Table"},[_vm._m(2),_c('TableSkeleton',{directives:[{name:"show",rawName:"v-show",value:( _vm.ordersList.length == 0 ),expression:" ordersList.length == 0 "}]})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Polaris-Page-Header"},[_c('div',{staticClass:"Polaris-Page-Header__MainContent"},[_c('div',{staticClass:"Polaris-Page-Header__TitleActionMenuWrapper"},[_c('div',[_c('div',{staticClass:"Polaris-Header-Title__TitleAndSubtitleWrapper"},[_c('div',{staticClass:"Polaris-Header-Title"},[_c('h1',{staticClass:"Polaris-DisplayText Polaris-DisplayText--sizeLarge"},[_vm._v("Orders")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Order ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Shipping to")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Total ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Payment status ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Shipment status ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Order ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Shipping to")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Total ")]),_c('th',{staticClass:"Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric",attrs:{"data-polaris-header-cell":"true","scope":"col"}},[_vm._v(" Status ")])])])
}]

export { render, staticRenderFns }